import { StrictMode, useEffect, useState } from 'react';
import './App.css';
import { BackendContext } from "./context/BackendContext";
import { WebSocketClient, WebSocketStatus } from "./context/WebSocketClientContext";
import HomePage from './components/HomePage';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { Client } from '@stomp/stompjs';

function App() {
  const inDev = process.env.NODE_ENV === 'development';
  // const [backend, setBackend] = useState("/api");
  const [client, setClient] = useState(null);
  const [connected, setConnected] = useState(false);

  // ONLY FOR MULTI-TENANCY
  const [backend, setBackend] = useState(window.location.hostname + "/api");
  const superKey = (new URL(window.location.href)).searchParams.get('superkey');
  console.log(`Superkey: ${superKey}`);

  if (backend.startsWith("${")) {
    !inDev && console.error('No backend url provided. Please provide a backend url in the environment variable REACT_APP_BACKEND_URL');
    setBackend(process.env.REACT_APP_BACKEND_URL || 'localhost:8080');
  }
  
  inDev && console.log(`Backend URL: ${backend}`);

  function connectToWebSocket() {
    if (connected) return;
    const client = new Client({
        brokerURL: `ws://${backend}/ws`,
        reconnectDelay: 300,
        heartbeatIncoming: 1000,
        heartbeatOutgoing: 1000,
    });
    client.activate();
    client.onConnect = () => {
        setConnected(true);
    }
    client.onStompError = (frame) => {
        console.error('Broker reported error: ' + frame.headers['message']);
        console.error('Additional details: ' + frame.body);
        disconnectFromWebSocket();
        setConnected(false);
    }
    client.onWebSocketClose = () => {
        disconnectFromWebSocket();
        setConnected(false);
    }
    client.onDisconnect = () => {
        disconnectFromWebSocket();
        setConnected(false);
    }
    setClient(client);
  }

    function disconnectFromWebSocket() {
        client && client.deactivate();
        setConnected(false);
    }

    useEffect(() => {
        connectToWebSocket();
    }, [connected]);

  const theme = createTheme({
    palette: {
      selectedBackground: '#797979',
      selectedColor: '#e0e0e0',
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: '#e0e0e0',
            color: '#000000',
            marginTop: '10px',
            width:'100%',
            '&:hover': {
              backgroundColor: '#797979',
              color: '#e0e0e0'
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: '#e0e0e0',
            color: '#000000',
            marginTop: '10px',
            width:'100%',
            '&:hover': {
              backgroundColor: '#797979',
              color: '#e0e0e0'
            }
          }
        },
        defaultProps: {
          variant: 'contained',
        }
      }
    }
  })

    return (
        <ThemeProvider theme={theme}>
        <div className="App">
        <StrictMode>
            <BackendContext.Provider value={backend}>
            <WebSocketClient.Provider value={client}>
                <WebSocketStatus.Provider value={connected}>
                <HomePage />
                </WebSocketStatus.Provider>
            </WebSocketClient.Provider>
            </BackendContext.Provider>
        </StrictMode>
        </div>
        </ThemeProvider>
    );
}

export default App;
