import { useState } from 'react';

/* Components and context */
import CodeBlockForm from './CodeBlockForm';
import ExecutedBlocksList from './ExecutedBlocksList';
import Logs from './LogsBox';

/* Material UI */
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import '../App.css';
import TopPart from './TopPart';
import LostConnectionAlert from './micro/LostConnectionAlert';
import PositionedAlert from './micro/PositionedAlert';

function HomePage() {
    const [jobId, setJobId] = useState();
    const [message, setMessage] = useState(null);
    return (
      <>
      <TopPart/>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
        <ExecutedBlocksList 
          jobId={jobId}
          setJobId={setJobId}
          setMessage={setMessage}
        />
          { !jobId ? 
            <CodeBlockForm setJobId={setJobId} setMessage={setMessage} />
            :
            <Logs 
              jobId={jobId}
              setJobId={setJobId}
              setMessage={setMessage}
            />
          }
        </Grid>
      </Box>
      <LostConnectionAlert />
      <PositionedAlert message={message} setMessage={setMessage} />
      </>
      )
}

export default HomePage