import { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ConnectedIndicator from './micro/ConnectedIndicator';
import { WebSocketStatus } from '../context/WebSocketClientContext';
import '../App.css';

function TopPart() {
    const connected = useContext(WebSocketStatus);

    return(
        <>
        <Typography variant="h3" component="div" sx={{ marginTop: '20px', marginBottom: '20px' }}>
          Sandbox Code Runner [{window.location.hostname}].
        </Typography>

       <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h6" component="div">
              Previously Executed Blocks
            </Typography>
          </Grid>
            
            <ConnectedIndicator connected={connected} />
        </Grid>
        </Box>
        </>
    );
}

export default TopPart;