import { useRef, useEffect } from 'react';

/**
 * Component to scroll to the bottom of the page. You can include this component in any div to scroll to the bottom of the div,
 * or where this component is located.
 * 
 * Condition here is the watched state that will trigger the scroll to the bottom of the page.
 */
function AutoBottom({
    condition
}) {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [condition]);

    return (
        <span id="bottom" style={{display: ''}} ref={ref} ></span>
    )
}

export default AutoBottom;