import { useContext, useState } from "react"
import { WebSocketStatus } from "../../context/WebSocketClientContext"
import PositionedAlert from "./PositionedAlert"

function LostConnectionIndicator() {
    const [message, setMessage] = useState("Lost connection to the server. Please wait until the connection is re-established");
    return(
        <PositionedAlert message={message} setMessage={setMessage} />
    )
}

function ConnectionEstablishedIndicator() {
    const [message, setMessage] = useState("Connection to the server established");
    return(
        <PositionedAlert message={message} setMessage={setMessage} />
    )
}

function LostConnectionAlert() {
    const connected = useContext(WebSocketStatus);
    return connected ? <ConnectionEstablishedIndicator /> : <LostConnectionIndicator />;
}

export default LostConnectionAlert;