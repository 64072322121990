import { useContext, useState } from 'react';
import '../App.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CodeEditor from '@uiw/react-textarea-code-editor';
import  Tooltip from '@mui/material/Tooltip';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';

import { BackendContext } from '../context/BackendContext';
import { WebSocketStatus } from '../context/WebSocketClientContext';


function CodeBlockForm({
    setJobId,
    setMessage,
}) {
    const backend = useContext(BackendContext);
    const connected = useContext(WebSocketStatus);

    const [codeBlock, setCodeBlock] = useState('');

    async function executeCodeBlock(event) {
        event.preventDefault();
        try {
            const org = window.location.host
            console.log(`Org: "${org}"`)
            const data = await fetch(
                `http://${backend}/api/v1/execute`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        code: codeBlock,
                        org: org
                    })
                }
            );
            const jsonData = await data.json();
            if (jsonData.error) {
                setMessage(jsonData.error);
                return;
            }
            setMessage('Code block sent successfully');
            setJobId(jsonData.jobId);
        } catch (error) {
            console.error('Error:', error);
            setMessage('Something went wrong. Please try again later.');
        }
    }

    function handleCodeChange(value) {
        setCodeBlock(value);
    }

    return (
        <Grid item xs={8}
              sx={{
                padding: '20px',
                maxWidth: '500px',
                marginLeft: '5px',
                marginRight: '0px',
                marginTop: '50px',
                borderRadius: '5px'
              }}
        >
        <CodeEditor
            value={codeBlock}
            onChange={(event) => handleCodeChange(event.target.value)}
            language="shell"
            placeholder="Please enter your code block here..."
            padding={15}
            style={{
                backgroundColor: "#f5f5f5",
                fontFamily: 'Fira Code,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                fontSize: '18px',
                height : '300px',
                overflow: 'auto',
            }}
        >
        </CodeEditor>  
        <br />
        <Tooltip
            title={
                connected ? (
                   codeBlock === '' ? 'Please enter a code block to execute' : 'Execute the code block'
                )
                : 'Please wait until the connection is established'
            }
        >
            <span>
            <Button
                onClick={executeCodeBlock}
                endIcon={
                    <SlowMotionVideoIcon/>
                }
                disabled={codeBlock === '' || !connected}
            >
            Execute Code Block
            </Button>
            </span>
        </Tooltip>
        </Grid>
    )
}

export default CodeBlockForm;