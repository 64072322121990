import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function formatTheStupidDate(datePlain) {
    if (datePlain === null) return null;
    const date = new Date(datePlain);
    const day = date.getDate().toString().padStart(2, '0'); 
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
}

const MetaDataStyledTextSkeleton = () => (
    <Skeleton variant="text" 
        sx={{
            width: '200px',
            // center
            marginLeft: 'auto',
            marginRight: 'auto',
        }}
    />
);

function MetaData({
    metadata
}) {
    let { id, code, start, end, duration, status, exitCode } = metadata;
    start = formatTheStupidDate(start);
    end = formatTheStupidDate(end);
    // duration is in ms and rounded to 2 decimal places
    duration = duration && (duration / 1000).toFixed(2);

    return (
        <Typography variant="body2" color="textSecondary" component="p">

            {
                start ?
                    <>Start: {start} <br /></>
                :
                <MetaDataStyledTextSkeleton />
            }
            {
                end ?
                    <>&nbsp;End: {end} <br /></>
                :
                <MetaDataStyledTextSkeleton />
            }
            {
                duration ?
                    <>Duration: {duration} s <br /></>
                :
                <MetaDataStyledTextSkeleton />
            }
            {
                exitCode != null ?
                    <>Exit Code: {exitCode} <br /></>
                :
                <MetaDataStyledTextSkeleton />
            }
            {
                code ?
                    <Accordion
                    sx={{
                    }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Command
                        </AccordionSummary>
                        <AccordionDetails>
                            <pre>
                            {code}
                            </pre>
                        </AccordionDetails>
                    </Accordion>
                    :
                    <Skeleton variant="text" />
            }
        </Typography>
    )
}


export default MetaData;