import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

function PositionedAlert({
  message,
  setMessage,
  verticalDef = 'bottom',
  horizontalDef = 'right',
  openDef = false,
}) {
  const [open, setOpen] = useState(openDef);
  const [vertical, setVertical] = useState(verticalDef);
  const [horizontal, setHorizontal] = useState(horizontalDef);

  useEffect(() => {
    if (message === null || message === undefined) {
      setOpen(false);
      return;
    }
    setOpen(true);
  }, [message])

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() => {setMessage(null);}}
        message={message}
        key={vertical + horizontal}
      />
    </Box>
  );
}

export default PositionedAlert;