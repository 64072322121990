import Stack from '@mui/material/Stack';
import { Suspense, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import '../App.css';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { BackendContext } from '../context/BackendContext';
import ExecutedBlock from './ExecutedBlock';
import { Skeleton } from '@mui/material';
import { WebSocketClient, WebSocketStatus } from '../context/WebSocketClientContext';


function ExecutedBlocksList({
    jobId,
    setJobId,
    setMessage,
}) {
    const backend = useContext(BackendContext);
    const client = useContext(WebSocketClient);
    const connected = useContext(WebSocketStatus);

    const [executedBlocks, setExecutedBlocks] = useState();
    const [jobsListSubscription, setJobsListSubscription] = useState();

    const [selectedJob, setSelectedJob] = useState();

    useEffect(() => {
        if (!connected) {return;}
        getAllExecutedBlocks();
        subscribeToJobsList();
        return () => {
            if(jobsListSubscription) jobsListSubscription.unsubscribe();
        }
      }, [connected]);

    useEffect(() => {
        if (!jobId) {setSelectedJob(null);}
        setSelectedJob(jobId);
    }, [jobId])

    function subscribeToJobsList() {
        if(!client || !connected) return;
        try {
            let url = `/topic/job/list/${window.location.hostname}`;
            const superKey = (new URL(window.location.href)).searchParams.get('superkey');
            if (superKey) {
                url = `/topic/job/list/super/${superKey}`;
            }
            const sub = client.subscribe(url, (message) => {
                addExecutedBlock(JSON.parse(message.body));
            });
            setJobsListSubscription(sub);
        } catch (error) {
            console.error('weird');
        }
    }

    /**
     * Convert the JSON dictionary to a dictionary of executed blocks.
     * So that we can easily access the executed block by its id.
     */
    function fromJsonToExecutedBlock(jsonData) {
        const finalData = {};
        jsonData?.forEach((block) => {
            finalData[block.id] = block;
        })
        setExecutedBlocks(finalData);
    }

    // Add the executed block to the executed block dictionary.
    function addExecutedBlock(block) {
        setExecutedBlocks((prev) => {
            return {
                ...prev,
                [block.id]: block
            }
        });
    }

    // Get all executed blocks from the backend.
    async function getAllExecutedBlocks() {
        try {
            const org = window.location.hostname;
            let url = `/api/v1/logs/org/${org}`;
            const superKey = (new URL(window.location.href)).searchParams.get('superkey');
            if (superKey) {
                url = `/api/v1/logs/super/${superKey}`;
            }
            const data = await fetch(
                `http://${backend}${url}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            const jsonData = await data.json();
            if (jsonData.error) {
                setMessage(jsonData.error);
                return;
            }
            fromJsonToExecutedBlock(jsonData);
        } catch (error) {
            setMessage('Something went wrong. Please try again later.');
        }
    }

    return (
        <Suspense fallback={<Skeleton variant="rectangular" width="100%" height={500}/>}>

        <Grid item 
            xs={3}
            sx={{
            maxWidth: '500px',
            marginTop: '20px',
            marginLeft: '20px',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            }}
        >
        {
            !executedBlocks || !connected ? 
            <Zoom in={true}>
                <Skeleton variant="rectangular" width="100%" height={500}/>
            </Zoom>
        :
            executedBlocks && Object.keys(executedBlocks).length === 0 ?
        
            <Zoom in={true}>
                <Typography variant="subtitle" component="div">
                    No executed blocks yet! 😓
                </Typography>
            </Zoom>
        :
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    maxHeight: '600px',
                    overflow: 'auto',
                    margin: 'auto',
                    padding: '10px',
                    position: 'relative',
                }}
            >
            {
            executedBlocks && Object.keys(executedBlocks).map((key, index) => {
                return (
                    <ExecutedBlock
                        key = {key}
                        id={key}
                        org={executedBlocks[key].org}
                        index={index}
                        status={executedBlocks[key].status}
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        setJobId={setJobId}
                    />
                )
            }).reverse()
            }
            </Stack>
        }
        </Grid>
        </Suspense>
    )

}


export default ExecutedBlocksList;