import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
import WifiIcon from '@mui/icons-material/Wifi';
import Tooltip from '@mui/material/Tooltip';
import { Grid, Paper, Typography } from '@mui/material';

/**
 * ConnectedIndicator component
 * 
 * It is a simple component that displays the connection status with the websocket server. 
 */
function ConnectedIndicator({
    connected
}) {
    return (
    <Grid item xs={9}>
    <Tooltip title="Connection status with the websocket server">
        <Paper
            sx={{
            padding: '3px',
            height: '25px',
            maxWidth: '25px',
            borderRadius: '5px',
            marginLeft: '44%',
            }}
        >
            <Typography variant="h6" component="div">                
            {connected ? <WifiIcon color='success' /> : <SignalWifiConnectedNoInternet4Icon color='error' />}
            </Typography>
        </Paper>
        </Tooltip>
    </Grid>
    );
}

export default ConnectedIndicator;