import '../App.css';
import { useEffect, useContext, useRef, useState } from 'react';
import { getColorFromStatus } from '../util/status';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CodeBlock, monokai } from 'react-code-blocks';

import AutoBottom from './micro/AutoBottom';
import { BackendContext } from '../context/BackendContext';
import { WebSocketClient, WebSocketStatus } from '../context/WebSocketClientContext';
import MetaData from './MetaData';

function Logs({jobId, setJobId}) {
    const client = useContext(WebSocketClient);
    const connected = useContext(WebSocketStatus);
    const backend = useContext(BackendContext);

    const [logs, setLogs] = useState();
    const [metaData, setMetaData] = useState();
    const [loading, setLoading] = useState(true);
    
    const JobSubRef = useRef(null);

    function addNewLogMessage(message) {
        setLogs((prev) => {
            if(!prev) return message.logs;
            return prev + message.logs;
        });
        setMetaData(message?.metadata);
        setLoading(false);
    }

    async function getLogs() {
        try {
            const data = await fetch(
                `http://${backend}/api/v1/logs/${jobId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            const jsonData = await data.json();
            setMetaData(jsonData?.metadata);
            setLogs(jsonData?.content);
            setLoading(false);
        } catch (error) {
            console.log('Something went wrong', error);
        }
    }

    useEffect(() => {
        if(!connected || !client) return;
        getLogs();
        const sub = client.subscribe('/topic/job/' + jobId, (message) => {
            addNewLogMessage(JSON.parse(message.body));
        });
        JobSubRef.current = sub;
        return () => {
            if(!JobSubRef) return;
            JobSubRef.current.unsubscribe();
        }
    }, [jobId]);



    return (
        <Grid item xs={8}
            sx={{
            padding: '20px',
            maxWidth: '500px',
            marginLeft: '5px',
            marginRight: '0px',
            marginTop: '20px',
            borderRadius: '5px'
            }}
        >
        <Paper elevation={1} 
            style={{
                padding: '10px', 
                marginBottom: '10px',
                backgroundColor: getColorFromStatus(metaData?.status),
            }}
        >
            <Typography variant="subtitle" component="div">
                Logs for Job: <pre>{jobId}</pre>
            </Typography>
            <Typography variant="subtitle" component="div">
                {metaData ? 
                    <MetaData metadata={metaData} />
                    :
                    <Skeleton variant="rectangular" width="100%" height={50}/>
                }
            </Typography>
        </Paper>

        {
        loading ? 
            <Skeleton variant="rectangular" width="100%" height={500}/>
        :
        <div
            style={{
                overflow: 'auto',
                padding: '10px',
                borderRadius: '5px',
                textAlign: 'left',
                maxHeight: '500px',
            }}
        >
        <div
            style={{
                fontFamily: 'monospace',
            }}
        >
            <CodeBlock
                text={logs}
                language="shell"
                showLineNumbers={true}
                theme={monokai}
                />
        </div>
        <AutoBottom condition={logs} />
        </div>
        }
        <Button
            endIcon = {<ArrowBackIcon />}
            onClick={() => {
                setJobId(null);
        }}>Back to Code</Button>
        </Grid>
    )
}


export default Logs;