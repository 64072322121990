function getColorFromStatus(status) {
    switch(status) {
        case 'SUCCESS':
            return '#388e3c';
        case 'FAILURE':
            return '#d32f2f';
        case 'RUNNING':
            return '#f3e5f5';
        case 'PENDING':
            return '#ffb74d';
        case 'TIMEOUT':
            return '#f57c00';
        default:
            return '#f3e5f5';
    }
}

export { getColorFromStatus };