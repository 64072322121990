import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';
import Grow from '@mui/material/Grow';
import ErrorIcon from '@mui/icons-material/Error';
import DangerousIcon from '@mui/icons-material/Dangerous';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HelpIcon from '@mui/icons-material/Help';
import TimerIcon from '@mui/icons-material/Timer';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

/**
 * ExecutedBlock component
 * 
 * It is simply a button that is displayed in the ExecutedBlocksList component.
 * It gets rendered using the "Grow" component from Material UI.
 *
 *  Once it is clicked, it sets the selected block and job id. 
 */
function ExecutedBlock({
    id,
    index,
    org,
    selectedJob,
    setSelectedJob,
    setJobId,
    status,
}) {
    return (
        <Grow in={true}>
        <Button
            onClick={() => {
                setJobId(id);
                setSelectedJob(id);
            }}
            sx= {{
                backgroundColor: selectedJob === id ? 'selectedBackground' : 'background',
                color: selectedJob === id ? 'selectedColor' : 'color',
            }}
        >
            {
                status === 'SUCCESS' ?
                <CheckCircleIcon color='success' sx={{marginRight: '5px'}} />
                :
                status === 'FAILURE' ?
                <DangerousIcon color='error' sx={{marginRight: '5px'}} />
                :
                status === 'RUNNING' ?
                <AutorenewIcon color='info' sx={{marginRight: '5px'}} />
                :
                status === 'UNKNOWN' ?
                <HelpIcon color='warning' sx={{marginRight: '5px'}} />
                :
                status === 'TIMEOUT' ?
                <TimerIcon color='warning' sx={{marginRight: '5px'}} />
                :
                <HourglassBottomIcon color='warning' sx={{marginRight: '5px'}} />
            }
            [{org}] Job #{index}
        </Button>
        </Grow>
    )
}


export default ExecutedBlock;